import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  PrivateSession,
  PrivateSessionsService,
  SessionResponse
} from '@appWidget/modules/booking-cancellation/services/private-sessions.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { Bookings } from '@appWidget/modules/booking/interfaces';
import { getBookingsFromSession } from '@app/shared/utils/get-bookings-from-session';

interface PrivateSessionState {
  status: 'initial' | 'loading' | 'idle';
  privateSession: SessionResponse | undefined;
}

const initialState: PrivateSessionState = {
  status: 'initial',
  privateSession: undefined
};

@Injectable()
export class SelectedPrivateSessionStore extends ComponentStore<PrivateSessionState> {
  readonly status$ = this.select(state => state.status);
  readonly isInitial$ = this.select(this.status$, status => status === 'initial');
  readonly isLoading$ = this.select(this.status$, status => status === 'loading');
  readonly isIdle$ = this.select(this.status$, status => status === 'idle');

  readonly privateSession$ = this.select(state => state.privateSession);

  readonly service$ = this.select(
    this.privateSession$,
    session =>
      // TODO take SessionResponse interface from backend
      session
  );
  readonly sessionEvents$ = this.select(this.privateSession$, (session): Bookings[] => {
    return session ? getBookingsFromSession(session) : [];
  });

  // Each new call of getMovie(id) pushed that id into movieId$ stream.
  readonly getPrivateSession = this.effect((movieId$: Observable<number>) => {
    return movieId$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading'
        });
      }),
      // 👇 Handle race condition with the proper choice of the flattening operator.
      switchMap(sessionId =>
        this.privateSessionsService.getById(sessionId).pipe(
          // 👇 Act on the result within inner pipe.
          tap({
            next: privateSession => {
              this.patchState({
                status: 'idle',
                privateSession
              });
            },
            error: error => {
              this.patchState({
                status: 'idle'
              });

              this.notificationsService.error(
                'System error',
                `Error while fetching a private session by id "${sessionId}"`,
                null,
                error
              );
            }
          }),
          // 👇 Handle potential error within inner pipe.
          catchError(() => EMPTY)
        )
      )
    );
  });

  // @ts-ignore
  get state() {
    return this.get();
  }

  constructor(
    private privateSessionsService: PrivateSessionsService,
    private notificationsService: NotificationsService
  ) {
    super(initialState);
  }
}
