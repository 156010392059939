import 'hammerjs';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@env/environment';
import { AppModule } from './app/app.module';
import { init } from '@sentry/angular';
import { browserTracingIntegration } from '@sentry/browser';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  if (environment.sentryKey) {
    init({
      dsn: environment.sentryKey,

      // Alternatively, use `process.env.npm_package_version` for a dynamic release version
      // if your build tool supports it.
      release: environment.NAME,
      environment: environment.NAME,
      integrations: [browserTracingIntegration()],
      tracesSampleRate: 0.1,
      ignoreErrors: ['ResizeObserver loop limit exceeded']
    });
  }
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
});
