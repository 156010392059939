import { DateTime } from 'luxon';
import { Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { ChatsService } from '@app/core/chat/chats.service';
import { SystemNotificationsService } from '@app/core/notifications/system-notifications.service';
import { HelpMeFindAGuideCommunicatorService } from '@app/core/public/help-me-find-a-guide-communicator.service';
import { QuizService } from '@app/core/quizzes/quiz.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { SessionsService } from '@app/core/session/sessions.service';
import { AuthModalComponent, AuthStates } from '@app/modules/auth/components/auth-modal/auth-modal.component';
import { ClientProgramsService } from '@app/modules/client-programs/services/client-programs.service';
import { PublicEventsService } from '@app/modules/public-events/services/public-events.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GlobalConfig } from '@cnf/types';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { HelpMeFindAGuideModalComponent } from './help-me-find-a-guide-modal/help-me-find-a-guide-modal.component';
import { PuiDialogService } from '@awarenow/profi-ui-core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-sidebar-navigation',
  templateUrl: './sidebar-navigation.component.html',
  styleUrls: ['./sidebar-navigation.component.scss']
})
export class SidebarNavigationComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _doesClientHaveAccessiblePrograms = false;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isHelpFindAGuideModalOpen: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _sessionRequestsCounter = 0;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _surveysCounter = 0;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _unreadChatMessagesCounter = 0;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _unreadProgramsCounter = 0;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _userRoleBasedSubscriptions: Subscription[] = [];

  UserRoles = UserRoles;

  GUIDES_LIST_ROUTE = environment.guidesRoute;

  GUIDE_LIST_ROUTE = environment.guideRoute;

  PUBLIC_EVENTS_ENABLED_FOR_CLIENTS: boolean;

  PUBLIC_EVENTS_ENABLED_FOR_GUIDES: boolean;

  config = {
    enableMarketplace: false,
    enablePrograms: false,
    enableSurveys: false,
    enableHelpMeFindAGuide: false,
    enableECourcesNav: false,
    enableBlogNav: false,
    enableSessionRoomNav: false
  };

  get canShowHelpMeFindAGuide(): boolean {
    return (
      (!this.authService.isAuthorized || this.authService.user.RoleId === UserRoles.CLIENT) &&
      this._router.url.startsWith(`/${this.GUIDES_LIST_ROUTE}`)
    );
  }

  get doesClientHaveAccessiblePrograms(): boolean {
    return this._doesClientHaveAccessiblePrograms;
  }

  get sessionRequestsCounter(): string {
    return this.formatCounter(this._sessionRequestsCounter);
  }

  get surveysCounter(): string {
    return this.formatCounter(this._surveysCounter);
  }

  get unreadChatMessagesCounter(): string {
    return this.formatCounter(this._unreadChatMessagesCounter);
  }

  get unreadProgramsCounter(): string {
    return this.formatCounter(this._unreadProgramsCounter);
  }

  constructor(
    public authService: AuthService,
    private _chats: ChatsService,
    private _quizzes: QuizService,
    private _sessions: SessionsService,
    private _systemNotifications: SystemNotificationsService,
    private _clientPrograms: ClientProgramsService,
    private _modal: NgbModal,
    private _helpFindAGuide: HelpMeFindAGuideCommunicatorService,
    private _router: Router,
    private _brandingService: BrandingService,
    private readonly _runtimeConfigService: RuntimeConfigService,
    readonly publicEventsService: PublicEventsService,
    private dialogService: PuiDialogService
  ) {
    // @ts-expect-error TS2322
    this.PUBLIC_EVENTS_ENABLED_FOR_GUIDES = this._runtimeConfigService.get('publicEventsEnabledForGuides');
    // @ts-expect-error TS2322
    this.PUBLIC_EVENTS_ENABLED_FOR_CLIENTS = this._runtimeConfigService.get('publicEventsEnabledForClients');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this.authService
      .onAuth()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.cleanUserRoleBasedSubscription();

        if (!user) {
          return;
        }

        if (user.RoleId !== UserRoles.ADMIN) {
          this.initializeAnyUserButAdminCounters();
        }

        if (user.RoleId === UserRoles.CLIENT) {
          this.initializeClientCounters();
        }

        if (user.RoleId === UserRoles.GUIDE) {
          this.initializeGuideCounters();
        }
      });

    this.publicEventsService.checkPublicEventsAvailability();
    this._helpFindAGuide.startHelp$.pipe(takeUntil(this.destroy$)).subscribe(() => this.helpToFindAGuide());
    this._clientPrograms.doesClientHaveAccessiblePrograms$
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => (this._doesClientHaveAccessiblePrograms = val));

    this._brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => this.setConfig(config));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async helpToFindAGuide(): Promise<void> {
    if (this._isHelpFindAGuideModalOpen) {
      return;
    }

    this._isHelpFindAGuideModalOpen = true;
    const { result } = this._modal.open(HelpMeFindAGuideModalComponent);

    try {
      await result;
      // eslint-disable-next-line id-length
    } catch (e) {}

    this._isHelpFindAGuideModalOpen = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  openSignIn() {
    const ref = this.dialogService.open(AuthModalComponent, {
      size: 'l',
      data: {
        activeState: AuthStates.INIT
      },
      hasCloseButton: true
    });
  }

  private cleanUserRoleBasedSubscription(): void {
    // eslint-disable-next-line id-length
    this._userRoleBasedSubscriptions.forEach(s => s.unsubscribe());
    this._userRoleBasedSubscriptions = [];
  }

  private formatCounter(counter: number): string {
    return counter > 0 ? (counter > 999 ? '999' : `${counter}`) : '';
  }

  private initializeAnyUserButAdminCounters(): void {
    this._userRoleBasedSubscriptions.push(
      this._chats.chatsSummaries$
        .pipe(
          map(users => users.reduce((sum, val) => sum + (val.notificationsCount ?? 0), 0)),
          takeUntil(this.destroy$)
        )
        .subscribe(unreadMessagesCount => (this._unreadChatMessagesCounter = unreadMessagesCount))
    );
  }

  private initializeClientCounters(): void {
    this._userRoleBasedSubscriptions.push(
      this._quizzes.usersReplay$
        .pipe(
          map(users => {
            const now = DateTime.local();
            return (
              users
                // @ts-expect-error TS2345
                .map(user => DateTime.fromISO(user.lastQuestionDate).plus({ hour: 8 }))
                .reduce((sum, date) => sum + (date.toSeconds() > now.toSeconds() ? 1 : 0), 0)
            );
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(surveysCounter => (this._surveysCounter = surveysCounter)),
      this._sessions.sessionOffers$
        .pipe(
          map(offers => offers.length),
          takeUntil(this.destroy$)
        )
        .subscribe(offersCount => (this._sessionRequestsCounter = offersCount)),
      this._systemNotifications.programNotifications$
        .pipe(
          map(notifications => notifications.reduce((sum, { isRead }) => sum + (isRead ? 0 : 1), 0)),
          takeUntil(this.destroy$)
        )
        .subscribe(programsCounter => (this._unreadProgramsCounter = programsCounter))
    );
  }

  private initializeGuideCounters(): void {
    this._userRoleBasedSubscriptions.push(
      this._sessions.sessionRequests$
        .pipe(
          map(requests => requests.length),
          takeUntil(this.destroy$)
        )
        .subscribe(requestsCount => (this._sessionRequestsCounter = requestsCount))
    );
  }

  private setConfig(globalConfig: GlobalConfig): void {
    this.config.enableMarketplace = globalConfig.enableMarketplace;
    this.config.enablePrograms = globalConfig.enablePrograms;
    this.config.enableSurveys = globalConfig.enableSurveys;
    this.config.enableHelpMeFindAGuide = globalConfig.enableHelpMeFindAGuide;
    this.config.enableECourcesNav = globalConfig.enableECourcesNav;
    this.config.enableBlogNav = globalConfig.enableBlogNav;
    this.config.enableSessionRoomNav = globalConfig.enableSessionRoomNav;
  }
}
