import { BehaviorSubject, merge, Observable } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IWorkspaceMember } from '@app/modules/workspaces/types';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { customValidatorWrapper } from '@app/screens/guide/guide-profile/components/guide-edit-profile/form-validators/custom-validator-wrapper';
import { PuiDestroyService } from '@awarenow/profi-ui-core';

export interface UserSelectFormControlValue {
  id: number;
  value: boolean;
  info: CRMClient;
}

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  providers: [PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSelectComponent implements OnDestroy {
  readonly MAX_DISPLAYED_USERS = 2;
  private readonly SELECTIONS_LIMIT_DEFAULT_VALUE = 99999999;

  form: UntypedFormGroup = new UntypedFormGroup({
    search: new UntypedFormControl(null),
    users: new UntypedFormArray([])
  });

  isDropdownOpen = false;

  selectedList$ = new BehaviorSubject<CRMClient[]>([]);

  preselectedUsers$ = new BehaviorSubject<{ [key: number]: boolean }>({});

  @Input() placeholder = `Choose client`;

  @Input() errorMessage = `Client is required`;

  @Input() selectionsLimit = this.SELECTIONS_LIMIT_DEFAULT_VALUE;

  @Input() set selectionList(list: CRMClient[]) {
    this.patchForm(list);
  }

  @Input() set preselectedList(list: CRMClient[] | IWorkspaceMember[]) {
    if (list?.length) {
      this.selectedList$.next([]);
      this.resetAll();

      const ids = [...list].reduce((acc, user) => {
        return { ...acc, [this.getId(user)]: true };
      }, {} as { [id: number]: boolean });

      this.preselectedUsers$.next(ids);
      const users = this.getUsersArray()?.value.map((user: UserSelectFormControlValue) => ({
        ...user,
        value: ids[user.id]
      }));

      this.getUsersArray()?.patchValue(users);
    }
  }
  @Output() changeSelectedList = new EventEmitter<CRMClient[]>();

  get isMultiOption(): boolean {
    return this.selectionsLimit !== 1;
  }

  constructor(
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.selectedList$.next([]);
    this.resetAll();
  }

  selectAllDisabled(): boolean {
    return this.isAllSelected() || this.selectionsLimit < Object.keys(this.getUsersArray().value).length;
  }

  isAllSelected(): boolean {
    return this.selectedList$.value?.length === this.getUsersArray().value?.length;
  }

  resetAll(): void {
    const users = this.getUsersArray().value.map((user: UserSelectFormControlValue) => ({
      ...user,
      value: false
    }));

    this.getUsersArray()?.patchValue(users);
    this.form.get('search')?.reset();
  }

  selectAll(event: PointerEvent): void {
    if (event.pointerType === 'mouse' && event.type === 'click') {
      const users = this.getUsersArray().value.map((user: UserSelectFormControlValue) => ({
        ...user,
        value: true
      }));

      this.getUsersArray()?.patchValue(users);
    }
  }

  disabled(user: CRMClient | IWorkspaceMember): boolean {
    if (this.selectionsLimit <= this.selectedList$.value.length) {
      const selectedIds = this.selectedList$.value.map(item => this.getId(item));

      return !selectedIds.includes(this.getId(user));
    }

    return this.selectionsLimit <= this.selectedList$.value.length;
  }

  readonly filterByNameOrEmail = (search: string) => (controlValue: UserSelectFormControlValue) => {
    if (!search?.trim()) {
      return true;
    }
    const user = controlValue.info;

    const searchExpression = new RegExp(search.toLocaleLowerCase().replace(/[|\\{}()[\]^$+*?.]/g, '\\$&'), 'i');

    return (
      searchExpression.test(String(`${user.firstName} ${user.lastName}`).toLocaleLowerCase()) ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      searchExpression.test(String(user?.email || (user as any).contacts?.email).toLocaleLowerCase())
    );
  };

  selectUser(control: UntypedFormControl): void {
    if (this.disabled(control.value)) {
      // If it is a single option reset and choose a new selected one
      if (!this.isMultiOption) {
        this.resetAll();
      } else {
        return;
      }
    }

    control.patchValue({
      ...control.value,
      value: !control.value.value
    });

    if (this.selectionsLimit <= this.selectedList$.value.length) {
      this.isDropdownOpen = false;
    }
  }

  getId(user: CRMClient | IWorkspaceMember): number {
    if ('userId' in user) {
      return user.userId as number;
    }

    if ('id' in user) {
      return user.id;
    }

    return (user as unknown as CRMClient).relationId;
  }

  getUsersArray(): UntypedFormArray {
    return this.form.get('users') as UntypedFormArray;
  }

  getUser(id: number, users: UserSelectFormControlValue[] = this.getUsersArray()?.value): CRMClient | undefined {
    return users.find(user => this.getId(user.info) === id)?.info;
  }

  disableKey(event: KeyboardEvent, keyCode: string): boolean {
    if (event.code === keyCode) {
      return false;
    }
    return true;
  }

  private patchForm(users: CRMClient[]): void {
    this.getUsersArray().clear();

    users?.forEach(user => {
      this.getUsersArray().push(
        new UntypedFormGroup(
          {
            id: new UntypedFormControl(this.getId(user)),
            value: new UntypedFormControl(this.preselectedUsers$.value[this.getId(user)] ?? false),
            info: new UntypedFormControl(user)
          },
          {
            validators: customValidatorWrapper(
              (control: AbstractControl): { [key: string]: Record<string, unknown> } | null => {
                const selectedUserLength = Object.values(control.value).filter(isSelected => isSelected).length;

                if (selectedUserLength > 0) {
                  return null;
                }

                return { required: { value: control.value } };
              },
              `Client is required`
            )
          }
        )
      );
    });

    const selectedList = this.getUsersArray()?.value.filter(
      (user: CRMClient) => this.preselectedUsers$.value[this.getId(user)]
    );

    this.selectedList$.next(selectedList);

    this.setFormSubscription();
  }

  private setFormSubscription(): void {
    const usersControl = this.form.get('users') as AbstractControl;

    merge(usersControl?.valueChanges)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        const selectedList = usersControl.value
          .filter((user: UserSelectFormControlValue) => user.value)
          .map((user: UserSelectFormControlValue) => this.getUser(user.id))
          .filter((item: CRMClient) => item);

        this.selectedList$.next(selectedList);
        this.changeSelectedList.emit(selectedList);
        this.form.markAllAsTouched();
      });
  }
}
