<mat-stepper
  [@.disabled]="true"
  linear
  [disableRipple]="true"
  (selectionChange)="onStepChange($event)"
  labelPosition="bottom"
  #stepper>
  <mat-step [completed]="completed[0]">
    <ng-template matStepLabel i18n="@@workspaceCreationStep1">Create workspace</ng-template>
    <div class="step-wrapper">
      <app-create-team-step (workspaceCreate)="onWorkspaceCreated()"></app-create-team-step>
    </div>
  </mat-step>
  <mat-step [completed]="completed[1]">
    <ng-template matStepLabel i18n="@@workspaceCreationStep2">Add team members</ng-template>
    <div class="step-wrapper">
      <app-invite-team-members (invited)="onInviteHandler($event)"></app-invite-team-members>
    </div>
  </mat-step>

  <!-- Icon overrides. -->
  <ng-template matStepperIcon="done">
    <pui-icon svgIcon="pui:status-done" size="xxxs"></pui-icon>
  </ng-template>
  <ng-template matStepperIcon="create">
    <pui-icon svgIcon="pui:status-cancelled" [size]="20"></pui-icon>
  </ng-template>
  <ng-template matStepperIcon="edit">
    <pui-icon svgIcon="pui:status-warning" [size]="20"></pui-icon>
  </ng-template>

  <!-- Temporary replace numbers with incomplete icon. -->
  <ng-template matStepperIcon="number">
    <pui-icon svgIcon="pui:status-cancelled" [size]="20"></pui-icon>
  </ng-template>
</mat-stepper>
