import { combineLatest, Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { ClientTimelineEventUtils } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/modules/guide-client-history/client-timeline-event.utils';
import {
  ClientTimelineEvent,
  TimelineEventType
} from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/modules/guide-client-history/types';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { normalizeFullName } from '@app/shared/utils/full-name';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { UserTimezoneStore } from '@libs/core/user-timezone.store';
import { dayEnd } from '@libs/utils/date/day-end';
import { dayStart } from '@libs/utils/date/day-start';
import { select, Store } from '@ngrx/store';

import { ClientHistoryViewModel } from './client-history-view.model';
import { fetchClientHistory } from './store/client-history/clients-history.actions';
import * as clientsHistory from './store/client-history/clients-history.selectors';
import { ClientsHistoryStore } from './store/client-history/types';

type FilterValue = {
  search: string;
  startDate: Date;
  endDate: Date;
};

const filterBySearch =
  (search?: FilterValue['search']) =>
  (event: ClientHistoryViewModel): boolean => {
    if (!search?.trim()) {
      return true;
    }

    const searchExpression = new RegExp(search.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&'), 'i');

    return (
      searchExpression.test(String(event.description.replace(/<[^>]*>/g, ''))) ||
      searchExpression.test(String(event.comment)) ||
      searchExpression.test(String(normalizeFullName(event.author)))
    );
  };

const filterByDate =
  (startDate?: FilterValue['startDate'], endDate?: FilterValue['endDate']) => (event: ClientHistoryViewModel) => {
    const eventDate = new Date(event.timestamp);
    const isGreaterOrEqual = !startDate || eventDate >= dayStart(startDate);
    const isLessOrEqual = !endDate || eventDate <= dayEnd(endDate);

    return isGreaterOrEqual && isLessOrEqual;
  };

@Component({
  selector: 'app-guide-client-history',
  templateUrl: './guide-client-history.component.html',
  styleUrls: ['./guide-client-history.component.scss'],
  providers: [PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideClientHistoryComponent implements OnInit {
  /**
   * Required property
   */
  @Input() clientId!: CRMClient['id'];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _historyState$ = this.store$.pipe(
    select(clientsHistory.getState),
    map(state => state[this.clientId])
  );

  readonly events$ = this._historyState$.pipe(map(state => state.data?.events));
  readonly status$ = this._historyState$.pipe(map(state => state.status));
  readonly eventViewModel$ = this.events$.pipe(
    map(events =>
      events?.map(
        event =>
          new ClientHistoryViewModel(event, {
            hasHostedByDisplayed: this.hasHostedByDisplayed(event)
          })
      )
    )
  );

  readonly todayDate = new Date();

  readonly filterFormGroup = new UntypedFormGroup({
    search: new UntypedFormControl(),
    startDate: new UntypedFormControl(),
    endDate: new UntypedFormControl()
  });

  readonly filtered$ = combineLatest([
    this.eventViewModel$,
    this.filterFormGroup.valueChanges.pipe(startWith(this.filterFormGroup.value)) as Observable<FilterValue>
  ]).pipe(
    map(([events, value]) => {
      if (!value) {
        return events;
      }

      const filteredByDate = events.filter(filterByDate(value.startDate, value.endDate));
      const filteredBySearch = filteredByDate.filter(filterBySearch(value.search));

      return filteredBySearch;
    })
  );

  timezoneOffset$ = this.userTimezone.timezoneOffset$;

  get searchControl(): UntypedFormControl {
    return this.filterFormGroup.get('search') as UntypedFormControl;
  }

  get startDateControl(): UntypedFormControl {
    return this.filterFormGroup.get('startDate') as UntypedFormControl;
  }

  get endDateControl(): UntypedFormControl {
    return this.filterFormGroup.get('endDate') as UntypedFormControl;
  }

  constructor(
    private readonly store$: Store<ClientsHistoryStore>,
    private readonly workspacesService: WorkspacesService,
    private readonly userTimezone: UserTimezoneStore,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>
  ) {}

  ngOnInit(): void {
    this.store$.dispatch(fetchClientHistory({ clientId: this.clientId }));

    this.handleDateChanges();
  }

  resetFilters(): void {
    this.filterFormGroup.setValue({
      search: null,
      startDate: null,
      endDate: null
    });
  }

  private hasHostedByDisplayed(clientTimelineEvent: ClientTimelineEvent): boolean {
    const host = ClientTimelineEventUtils.getHost(clientTimelineEvent);

    if (!this.workspacesService.isTeamAdmin || this.workspacesService.workspace.guideId === host.id) {
      return false;
    }

    switch (clientTimelineEvent.eventType) {
      case TimelineEventType.SESSION_CANCELLED_BY_CLIENT:
      case TimelineEventType.SESSION_CANCELLED_BY_HOST:
      case TimelineEventType.SESSION_RESCHEDULED_BY_CLIENT:
      case TimelineEventType.SESSION_COMPLETED:
      case TimelineEventType.SESSION_BOOKED:
      case TimelineEventType.SESSION_ACCEPTED:
      case TimelineEventType.SESSION_MISSED_BY_CLIENT:
      case TimelineEventType.SESSION_OFFERED_BY_ADMIN:
      case TimelineEventType.SESSION_CANCELLED_BY_ADMIN:
      case TimelineEventType.SESSION_RESCHEDULED_BY_ADMIN:
        return true;
      default:
        return false;
    }
  }

  private handleDateChanges(): void {
    /**
     * Handle endDate changes.
     */
    this.endDateControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((endDate: Date) => {
      const startDate = this.startDateControl.value;

      /**
       * If next endDate > startDate should set startDate.
       */
      if (startDate > endDate) {
        this.startDateControl.setValue(dayStart(endDate));
      }
    });
  }
}
