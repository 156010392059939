<h1 class="chat-list-title" transloco="chatListCompHeaderTitle" data-qa-id="chat-list-secure-messaging-title">
  Secure messaging
</h1>
<div class="search-control">
  <button class="btn search-btn"></button>
  <input
    type="text"
    class="search-input"
    placeholder="Search chats"
    [(ngModel)]="searchText"
    i18n-placeholder="@@ChatsListCompSearchInputPlaceholder"
    data-qa-id="chat-list-search-input" />
</div>

<ng-template [ngIf]="chats && chats.length" [ngIfElse]="emptyChatList">
  <div class="chat-list-scroll-wrapper">
    <ng-scrollbar class="chat-list-scroll" [visibility]="'hover'" [appearance]="'compact'">
      <ul pui-list>
        <li
          pui-list-item="button"
          selected="{{ chat.id === selectedChatId }}"
          *ngFor="let chat of chats | filterChatList: searchText"
          (click)="emitSelectChat(chat)"
          [ngClass]="{ opacity: chat.archived }">
          <ui-card class="ui-card-flat" data-qa-id="chat-card">
            <ui-card-header [ngClass]="{ archived: chat.archived }">
              <!-- eslint-disable -->

              <pui-avatar
                ui-card-avatar
                *ngIf="chat.type != ChatTypes.GROUP"
                [uiBadge]="chat.notificationsCount"
                [matBadgeHidden]="!chat.notificationsCount"
                matBadgeColor="warn"
                uiIconBadge="online-status"
                uiBadgeIconColor="primary"
                [uiIconBadgeHidden]="!chat.isOnline"
                [avatarUrl]="chat.photo"
                [text]="chat.name"
                [attr.data-qa-id]="'chats-list-user-avatar-' + chat.name"></pui-avatar>

              <!-- eslint-enable -->

              <app-group-chat-icon
                ui-card-avatar
                [uiBadge]="chat.notificationsCount"
                [matBadgeHidden]="!chat.notificationsCount"
                *ngIf="chat.type === ChatTypes.GROUP"
                [letter]="chat.name | firstLetters | uppercase"
                matBadgeColor="warn"></app-group-chat-icon>
              <ui-card-title>
                <div class="chat-name">
                  <span
                    i18n="@@chatsListChatNameWithArchived"
                    [attr.data-qa-id]="'chats-list-card-name-title-' + chat.name">
                    {{ chat.name }}{{ chat.archived ? ' (archived)' : '' }}
                  </span>
                  <ui-card-time align="end" [attr.data-qa-id]="'chats-list-time-title-' + chat.name">
                    {{
                      chat.type !== ChatTypes.CONTACT
                        ? (chat.lastMessageDate | platformDateTimeHybrid: { hideWeekDay: true })
                        : ''
                    }}
                  </ui-card-time>
                </div>
              </ui-card-title>
              <ui-card-subtitle *ngIf="chat.lastMessage">
                <dd
                  [innerHTML]="chat.lastMessage"
                  [attr.data-qa-id]="'chats-list-last-message-title-' + chat.name"></dd>
              </ui-card-subtitle>
            </ui-card-header>
          </ui-card>
        </li>
      </ul>
    </ng-scrollbar>
  </div>
</ng-template>

<ng-template #emptyChatList>
  <span i18n="@@chatsListEmptyState" class="no-active-chats">No active chats</span>
</ng-template>
